<template>
	<div class="mod-config">
		<el-row style="margin-top: 10px;">
			<el-col :span="12" :offset="12" style="text-align: right;">
				<el-pagination
				  background
				  layout="prev, pager, next"
				  :pager-count="5"
				  :page-size="20"
				  :total="200">
				</el-pagination>
			</el-col>
		</el-row>
		<div :style="siteContentViewHeight()" style="margin-top: 20px;">
			<el-card
				style="height: 100%;" 
				body-style="height: 100%; padding: 0;">
				<el-row style="height: 100%;">
					<el-col :span="4" style="background-color: #e9f4fb; height: 100%; overflow: auto;">
						<el-tree 
							:data="filesData" 
							:props="defaultProps" 
							default-expand-all="true"
							@node-click="handleNodeClick">
						</el-tree>
					</el-col>
					<el-col :span="20" style="height: 100%;">
						<el-table
							class="dataListTable"
							:data="tableData"
							header-align="center"
							height="100%"
							style="width: 100%;"
							header-cell-class-name="dataListTHeader"
							@selection-change="handleSelectionChange">
							<el-table-column
							  prop="name"
							  label="名称"
							  width="600"
							  align="left">
							  <template slot-scope="scope">
								<i class="el-icon-document" style="color: #00428e; font-size: 20px; vertical-align: middle;"></i>
								<span style="margin-left: 5px; color: #00428e; vertical-align: middle;">{{scope.row.name}}</span>
							  </template>
							</el-table-column>
							<el-table-column
							  prop="size"
							  label="大小"
							  width="110"
							  align="center">
							</el-table-column>
							<el-table-column
							  prop="num"
							  label="下载次数"
							  width="110"
							  align="center">
							</el-table-column>
							<el-table-column
							  prop="time"
							  label="最后更新 "
							  width="230"
							  align="center">
							</el-table-column>
							<el-table-column
							  prop="desc"
							  label="操作 "
							  align="center">
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
			</el-card>
		</div>
	</div>
</template>

<script>
	export default {
	  data() {
	    return {
	      tabIndex: "0",
		  addOrUpdateVisible: false,
		  filesData:[{
			  label: '一级 1',
			  children: [{
				label: '二级 1-1',
				children: [{
				  label: '三级 1-1-1'
				}]
			  }]
			}, {
			  label: '一级 2',
			  children: [{
				label: '二级 2-1',
				children: [{
				  label: '三级 2-1-1'
				}]
			  }, {
				label: '二级 2-2',
				children: [{
				  label: '三级 2-2-1'
				}]
			  }]
			}, {
			  label: '一级 3',
			  children: [{
				label: '二级 3-1',
				children: [{
				  label: '三级 3-1-1'
				}]
			  }, {
				label: '二级 3-2',
				children: [{
				  label: '三级 3-2-1'
				}]
			  }]
			}],
			defaultProps: {
			  children: 'children',
			  label: 'label'
			},
			tableData:[
				{
					name:"中国船级社实业公司业务平台后台维护手册--人力资源.doc",
					path:"",
					size:"1.42MB",
					num:"2",
					time:"2021-08-03",
					desc:"属性 日志"
				},
				{
					name:"中国船级社实业公司业务平台后台维护手册--人力资源.doc",
					path:"",
					size:"1.42MB",
					num:"2",
					time:"2021-08-03",
					desc:"属性 日志"
				}
			]
	    };
	  },
	  components: {
		  
	  },
	  activated() {
	
	  },
	  methods: {
		siteContentViewHeight () {
		  var height = this.$store.state.common.documentClientHeight - 250;
		  return { height: height + 'px' }
		},
		handleNodeClick(data) {
		}
	  }
	};
</script>

<style>
</style>
